import React from "react"
import { GatsbyImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Banner from "../components/banner"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
const banner_title = ['Shopify', 5000, 'Woocommerce.', 4000, 'Magento', 3000, 'BigCommerce', 2000, 'And More…', 1000]; 

/*
function spliceIntoChunks(arr, chunkSize) {
  const res = [];
  while (arr.length > 0) {
      const chunk = arr.splice(0, chunkSize);
      res.push(chunk);
  }
  return res;
}

*/


const service_listing = ( {data} ) => {

  //console.log(spliceIntoChunks(data.allWpFullService.nodes[0].fullServices.services, 2));
//console.log('fullservice',  data.allWpFullService.nodes[0].fullServices.services.splice(0,2));

return (

<Layout>
<Seo title="Get Web Design Development and Digital Market at one spot.  " />
<Banner className = "service" title = {banner_title}  sticky_first = "Estore company is" sticky_second  = "specialized in"/>

<section className = "intro_part" >

    <div className = "container">

    <div dangerouslySetInnerHTML={{ __html: data.allWpFullService.nodes[0].fullServices.introduction}}></div>
       
    </div>


</section>


<section className = "service_grid">

            <div className = "container">

            <ul  className = "service_box">

            {
      
      data.allWpFullService.nodes[0].fullServices.services.map(service => {

       
         
        return <li>

        <div className = "service_img">

        <GatsbyImage
      image={getImage(service.serviceImage.localFile)}
     
      alt={service.serviceTitle}
      />

        </div>

        <div className = "service_content">
            <h4>{service.serviceTitle}</h4>
            <h2>{service.serviceSubTitle}</h2>
            <p>{service.serviceDescription}</p>
<Link to = "/contact-us">get started</Link>

        </div>


    </li>
     })}

    
</ul>
 </div>
</section>


<section className = "schedule_call">


      <div className ="container">

      <div><h3>out-of-the-box-solutions</h3><h1>saving you time and money</h1></div>
        
        <Link to ="https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discoverycall" className ="sc_call" >schedule a call</Link>

      </div>



</section>





</Layout>




)






}

export const fullService = graphql`
query fullservice {
    allWpFullService {
      nodes {
        fullServices {
          introduction
          services {
            serviceTitle
            serviceSubTitle
            serviceDescription
            serviceImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }`


  


export default service_listing;